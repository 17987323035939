
import { Button } from "@mui/material";
import { ReactComponent as BlackDiamondLogo } from "../static/images/diamond.inline.svg";
import { Circle, Square } from "@mui/icons-material";
import constants from "../static/constants.json";


export default function DifficultyOption (props) {
    return (
      <Button
        variant="outlined"
        style={{
          backgroundColor: props.selected ? '#e5e9f2' : '#ffffff',
          borderWidth: props.selected ? '3px' : '1px',
          display: "flex",
          flexDirection: "column",
          width: "100px",
          height: "100px",
          margin: "10px"
        }}
        onClick={() => props.onSelect()}
      >
        {getIconForDifficulty(props.difficulty)}
        <br />
        {props.text}
      </Button>
    );
  };
  

  
  const getIconForDifficulty = (diff, newProps) => {
    if (diff == constants.difficulty.easy) {
      return (
        <Circle
          style={{
            height: "20px",
            width: "20px",
            color: "green",
          }}
        />
      );
    } else if (diff == constants.difficulty.medium) {
      return (
        <Square
          style={{
            height: "20px",
            width: "20px",
            color: "blue",
          }}
        />
      );
    } else {
      return (
        <BlackDiamondLogo
          style={{
            height: "40px",
            width: "40px",
          }}
        />
      );
    }
  };
  