import { toast } from "react-toastify";


const defaultToastOptions = {
  position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    className: 'toast-notification',
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
};

const showError = (msg) => {
  toast.error(msg, defaultToastOptions);
};

const showSuccess = (msg) => {
  toast.success(msg, defaultToastOptions);
};

const showWarning = (msg) => {
  toast.warn(msg, defaultToastOptions);
};

export { showError, showSuccess, showWarning };
