import { createSlice } from "@reduxjs/toolkit";
import constants from "../static/constants.json";
import { calculateDistance } from "../utils/geography";
const defaultCurrentJump = {
  id: null,
  medias: [],
};

export const jumpsSlice = createSlice({
  name: "jumps",
  initialState: {
    list: [],
    filteredList:[],
    listSettings: {
      difficulties: [
        constants.difficulty.easy,
        constants.difficulty.medium,
        constants.difficulty.hard,
      ],
    },
    current: defaultCurrentJump,
  },

  reducers: {
    resetDefaults: (state, action) => {
      state.listSettings.difficulties = [
        constants.difficulty.easy,
        constants.difficulty.medium,
        constants.difficulty.hard,
      ]
    },
    setJumps: (state, action) => {
      state.list = action.payload;
    },
    setFilteredJumps: (state, action) => {
      state.filteredList = action.payload;
    },
    resetFilteredJumps: (state, action) => {
      state.filteredList = state.list;
    },
    setCurrentJump: (state, action) => {
      state.current = action.payload;
    },
    clearCurrentJump: (state, action) => {
      state.current = defaultCurrentJump;
    },

    addMediaKeyToJump: (state, action) => {
      const item = state.list.find((i) => i.id === action.payload.id);
      if (item) {
        item.medias.push({ key: action.payload.key, id: action.payload.id });
        if (state.current.id == item.id) {
          state.current.medias.push({
            key: action.payload.key,
            id: action.payload.id,
          });
        }
      }
    },
    deleteMedia: (state, action) => {
      // remove reference to media in jump list
      const item = state.list.find((i) => i.id === action.payload.jumpId);
      if (item) {
        item.medias = item.medias.filter(
          (m) => m.id !== action.payload.mediaId
        );
      }

      // remove reference in current jump
      state.current.medias = state.current.medias.filter(
        (m) => m.id !== action.payload.mediaId
      );
    },
    addDifficultyFilter: (state, action) => {
      const id = action.payload.difficultyId;
      if (state.listSettings.difficulties.indexOf(id) < 0) {
        state.listSettings.difficulties.push(id);
      }
    },
    removeDifficultyFilter: (state, action) => {
      const id = action.payload.difficultyId;
      if (state.listSettings.difficulties.indexOf(id) > -1) {
        state.listSettings.difficulties.splice(
          state.listSettings.difficulties.indexOf(id),
          1
        );
      }
    },
    sortJumpsByDistance: (state, action) => {
      const pointLat = action.payload.latitude;
      const pointLong = action.payload.longitude;

      // assign each jump a distance
      if (state.list) {
        state.list.forEach(j => {
          j.dist = calculateDistance(
            pointLat,
            pointLong,
            j.latitude,
            j.longitude
          );
        });
  
        // sort based on that distance
        state.list.sort((a, b) => {
          return a.dist - b.dist;
        });
      }
      
    },
  },
});

export const {
  setJumps,
  resetFilteredJumps,
  setFilteredJumps,
  setCurrentJump,
  clearCurrentJump,
  addMediaKeyToJump,
  deleteMedia,
  addDifficultyFilter,
  removeDifficultyFilter,
  sortJumpsByDistance,
  resetDefaults
} = jumpsSlice.actions;

export default jumpsSlice.reducer;
