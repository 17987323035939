import {
  Dialog,
  Button,
  Divider,
  List,
  ListItem,
  Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Typography,
} from "@mui/material";

import { GoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { InstagramLogin } from "@amraneze/react-instagram-login";
import { Instagram } from "@mui/icons-material";
import "../styles/instabutton.css";
import { UserAPI } from "../apis/userApi";
import { setUser } from "../redux/userSlice";
import { useEffect } from "react";

export default function UserMenu(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  // const [signedIn, setSignedIn] = useState(false);
  const instagramSuccess = async (response) => {
    const at = await UserAPI.getInstagramAccessToken(response);
    localStorage.setItem("token", at);
    localStorage.setItem("token_type", "instagram");

    props.onLoginSuccess();
    props.onCancel();
  };

  // useEffect(() => {

  // }. []);

  const instagramError = (response) => {
    // TODO handle me
    console.log("instagrammm failed", response);
  };

  const signOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("token_type");
    dispatch(setUser({}));
    // props.onCancel();
  };

  const signedInText = (
    <Typography
      variant="caption"
      style={{
        fontStyle: "italic",
        color: "#1876d1",
        marginTop: "0.5rem",
        width: "100%",
        textAlign: "center",
      }}
    >
      Signed In
    </Typography>
  );

  const signInButtons =(

      <Box>
      <Box style={{ textAlign: "center", margin: "10px", width: "208px" }}>
        <GoogleLogin
          text="Google"
          width="208px"
          onSuccess={(credentialResponse) => {
            localStorage.setItem("token_type", "google");
            localStorage.setItem("token", credentialResponse.credential);
            props.onLoginSuccess();
            props.onCancel();
          }}
          onError={() => {
            alert("Login Failed");
          }}
          useOneTap
        />
        {user && user.id && localStorage.getItem("token_type") == "google"
          ? signedInText
          : null}
      </Box>

      <Box
        style={{
          height: "40px",
          textAlign: "center",
          margin: "10px",
          width: "208px",
        }}
      >
        <InstagramLogin
          clientId="1428904094633907"
          onSuccess={instagramSuccess}
          onFailure={instagramError}
          cssClass="instalogin"
        >
          <Instagram style={{ marginRight: "8px" }} /> Sign in with Instagram
        </InstagramLogin>
        {user && user.id && localStorage.getItem("token_type") == "instagram"
          ? signedInText
          : null}
      </Box>
      </Box>
    )
  

  const userInfoSection  = (
    <Box style={{alignItems: 'center', textAlign: 'center'}}>
      <Typography>{user.name && user.name} ({user.provider && user.provider})</Typography>
      <Box>
        <Button variant="outlined" onClick={() => signOut()}>Sign Out</Button>
      </Box>
    </Box>
  );

  

  return (
    <Dialog
      fullWidth
      anchor="right"
      open={props.isOpen}
      onClose={() => props.onCancel()}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        {user && user.id ? "" : "Sign in"}
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
       {user && user.id ? userInfoSection : signInButtons }
      </DialogContent>
      {/* <DialogActions>
        {user && user.id ? (
          <Button onClick={() => signOut()}>Sign Out</Button>
        ) : null}

        <Button onClick={() => props.onCancel()}>Cancel</Button>
      </DialogActions> */}
    </Dialog>
  );
}
