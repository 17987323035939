import api from "./config/axiosConfig"

export const JumpApi = {
  list: async function (cancel = false) {
    try {
      const response = await api.request({
        url: `/jumps`,
        method: "GET",
        baseURL: process.env.REACT_APP_API_BASE_PATH,
      })
  
      return response.data
    }
    catch(e) {
      console.log('jumps api failed')
    }
    
  },
  create: async function (body, cancel = false) {
    try {
      const response = await api.request({
        url: `/jumps`,
        method: "POST",
        baseURL: process.env.REACT_APP_API_BASE_PATH,
        data: body,
        headers: { 
          "Authorization": "Bearer "+ localStorage.getItem('token'),
          "Content-Type": "application/json" ,
          "token-type": localStorage.getItem('token_type')
      },
      })
  
      return response.data;
    }
    catch(e) {
      console.log('creating jump failed')
      throw e;
    }
    
  },
  uploadMedia: async function (file, jumpId, cancel = false) {
    try {
      const formData = new FormData();
      formData.append("media", file);
      const response = await api.request({
        url: `/jumps/${jumpId}/media`,
        data: formData,
        method: "POST",
        baseURL: process.env.REACT_APP_API_BASE_PATH,
        headers: { 
          "Authorization": "Bearer "+ localStorage.getItem('token'),
          "Content-Type": "multipart/form-data" ,
          "token-type": localStorage.getItem('token_type')
      },
      })
  
      return response.data
    }
    catch(e) {
      console.log('jumps api failed', e)
    }
    
  },
  likeMedia: async function (mediaId, value, cancel = false) {
    try {
      
      const response = await api.request({
        url: `/media/${mediaId}/like`,
        data: {
          value
        },
        method: "POST",
        baseURL: process.env.REACT_APP_API_BASE_PATH,
        headers: { 
          "Authorization": "Bearer "+ localStorage.getItem('token'),
          "Content-Type": "application/json" ,
          "token-type": localStorage.getItem('token_type')
      },
      })
  
      return response.data
    }
    catch(e) {
      console.log('jumps api failed', e)
    }
    
  },

  deleteMedia: async function (mediaId, cancel = false) {
    try {
      
      const response = await api.request({
        url: `/media/${mediaId}`,
        method: "DELETE",
        baseURL: process.env.REACT_APP_API_BASE_PATH,
        headers: { 
          "Authorization": "Bearer "+ localStorage.getItem('token'),
          "Content-Type": "application/json" ,
          "token-type": localStorage.getItem('token_type')
      },
      })
  
      return response.data
    }
    catch(e) {
      throw e;
      console.log('jumps api failed', e)
    }
    
  }
}
