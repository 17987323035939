import * as React from "react";

import { IconButton, Tooltip } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { JumpApi } from "../apis/jumpApi";
export default function UploadButton(props) {
  const fileChosen = async (e) => {
    if (e.target.files[0].size > 10485760) {
      props.onError("File is too big! (10MB file limit)");
    } else {
      try {
        const mediaCreated = await JumpApi.uploadMedia(
          e.target.files[0],
          props.jump.id
        );
        props.onSuccess(mediaCreated);
      } catch (error) {
        props.onError(error);
      }
    }
  };

  return (
    <Tooltip title="Upload media">

    
    <IconButton variant="contained" component="label">
      <Upload />
      <input
        type="file"
        name="media"
        hidden
        accept=".jpeg,.png,.webp,.gif,.avif,.apng,.mp4"
        onChange={fileChosen}
      />
    </IconButton>
    </Tooltip>
  );
}
