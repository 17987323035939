import React from "react";
import { SvgIcon } from "@mui/material";
import { ReactComponent as CliffLogo } from "../../static/images/cliff0.svg";
import { ReactComponent as KnuckleLogo } from "../../static/images/knuckle0.svg";
import { ReactComponent as BooterLogo } from "../../static/images/booter0.svg";
import { ReactComponent as BlackDiamondLogo } from "../../static/images/diamond.inline.svg";
import { ReactComponent as MountainLogo } from "../../static/images/mountain.svg";


const Booter = (props) => {
  return (
    <SvgIcon sx={props.style}>
      <BooterLogo />
    </SvgIcon>
  );
};

const Cliff = (props) => {
  return (
    <SvgIcon sx={props.style}>
      <CliffLogo />
    </SvgIcon>
  );
};

const Knuckle = (props) => {
  return (
    <SvgIcon sx={props.style}>
      <KnuckleLogo />
    </SvgIcon>
  );
};

const SkiResort = (props) => {
  return (
    <SvgIcon sx={props.style}>
      <SkiResortLogo />
    </SvgIcon>
  );
};

const BlackDiamond = (props) => {
  return (
    <SvgIcon sx={props.style}>
      <BlackDiamondLogo />
    </SvgIcon>
  );
};

const Mountain = (props) => {
  return (
    <SvgIcon color="white" sx={props.style}>
      <MountainLogo color="white"/>
    </SvgIcon>
  );
};

export { Cliff, Knuckle, Booter, SkiResort, BlackDiamond, Mountain };
