import api from "./config/axiosConfig"

export const UserAPI = {
  get: async function (cancel = false) { 
    if (localStorage.getItem('token')) {
      const response = await api.request({
        url: `/user`,
        method: "GET",
        baseURL: process.env.REACT_APP_API_BASE_PATH,
        headers: {
          "Authorization": "Bearer "+ localStorage.getItem('token'),
          "token-type": localStorage.getItem('token_type')
        }
      })
  
      return response.data
    } 
    else return {};
    
  },
  getInstagramAccessToken: async function(code) {
    try {
      const response = await api.request({
        url: `/user/insta`,
        method: "GET",
        params: {
          code
        },
        baseURL: process.env.REACT_APP_API_BASE_PATH,
      });
      return response.data.access_token;
    } catch (error) {
      console.log('respond appropriately to this error', error);
    }
    
    
  }
}
