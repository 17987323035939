import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Overlay } from "pigeon-maps";
import { IconButton, CardActions, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener } from "@mui/base";
import constants from "../static/constants.json";
import { calculateDistance } from "../utils/geography";
import ReactPlayer from "react-player";
import JumpTypeBox from "./JumpTypeBox";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Delete,
  Favorite,
} from "@mui/icons-material";

import { addLike, removeLike } from "../redux/userSlice";
import { deleteMedia } from "../redux/jumpsSlice";

import SwipeableViews from "react-swipeable-views";



import { getChipForDifficulty } from "./DifficultyChips";

import {
  Snackbar,
  Alert,
  MobileStepper,
  Card,
  Button,
  CardMedia,
  CardContent,
  Typography,
  Tooltip,
  Paper,
  Modal,
  Box,
} from "@mui/material";

import useWindowDimensions from "../hooks/window";
import UploadButton from "./UploadButton";
import { addMediaKeyToJump } from "../redux/jumpsSlice";
import { JumpApi } from "../apis/jumpApi";
import { showError } from "../utils/notifier";

export default function JumpOverlay(props) {
  const theme = useTheme();
  const playerRef = useRef();
  const mediaBasePath = `https://storage.googleapis.com/${process.env.REACT_APP_BUCKET_NAME}`;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [dist, setDist] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.jump.medias.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (user.location) {
      const d = calculateDistance(
        user.location[0],
        user.location[1],
        props.jump.latitude,
        props.jump.longitude
      );

      setDist(d);
    }
  }, [user.location]);

  const likeMedia = async (mediaId, newValue) => {
    try {
      await JumpApi.likeMedia(mediaId, newValue);
      if (newValue) {
        dispatch(
          addLike({
            mediaId,
          })
        );
      } else {
        dispatch(
          removeLike({
            mediaId,
          })
        );
      }
    } catch (error) {
      console.log("error liking / unliking:", error);
    }
  };

  const deleteMediaFn = async (jumpId, mediaId) => {
    try {
      await JumpApi.deleteMedia(mediaId);

      dispatch(
        deleteMedia({
          mediaId,
          jumpId,
        })
      );
    } catch (error) {
      console.log("error deleting media:", error);
    }
  };

  useEffect(() => {}, [props.jump]);

  const focusedJumpDifficultyChip = getChipForDifficulty(
    props.jump.difficultyId
  );

  const addedMedia = (m) => {
    dispatch(
      addMediaKeyToJump({
        id: props.jump.id,
        key: m.key,
      })
    );
  };

  const noMediaContent = (

    <CardContent>
      <Box sx={{ height: "40vh", width: '100%',borderRadius: "10px", border: '1px solid #dadce0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

      <Typography variant='h5'>No photos yet</Typography>
      </Box>
    </CardContent>


    
  );

  const swipeableviews = (
    <SwipeableViews
      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
      index={activeStep}
      onChangeIndex={handleStepChange}
      enableMouseEvents
    >
      {props.jump.medias.map((media, index) => {
        const userLikedThisMedia =
          user && user.id
            ? user.likes.find((l) => l.mediaId == media.id)
            : false;
        const inner =
          media.mediaTypeId === constants.mediaType.image ? (
            <CardMedia
              sx={{ height: "40vh", borderRadius: "10px" }}
              image={`${mediaBasePath}/${media.key}`}
            >
              {user && user.id == media.createdById ? (
                <Tooltip title="Delete media">
                  <IconButton
                    style={{ float: "right" }}
                    aria-label="delete"
                    color="cancel"
                    onClick={() => deleteMediaFn(props.jump.id, media.id)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              ) : null}

              {user && user.id ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title={userLikedThisMedia ? "Unlike" : "Like"}>
                    <IconButton
                      style={{ float: "left" }}
                      aria-label="like"
                      color={userLikedThisMedia ? "error" : "cancel"}
                      onClick={() =>
                        likeMedia(media.id, !userLikedThisMedia)
                      }
                    >
                      <Favorite />
                    </IconButton>
                  </Tooltip>
                  <Typography variant="caption">{media.likes}</Typography>
                </div>
              ) : null}
            </CardMedia>
          ) : (
            <ReactPlayer
              style={{
                background: "#000000",

                borderRadius: "10px",
              }}
              width={"100%"}
              ref={playerRef}
              loop
              playing={activeStep == index}
              url={`${mediaBasePath}/${media.key}`}
            />
          );
        return (
          <Box key={`media_${index}`} style={{ margin: "10px" }}>
            {inner}
          </Box>
        );
      })}
    </SwipeableViews>
  );

  const card = (
    <Card style={{ maxWidth: "60vh", margin: "auto", border: "none" }}>
      {props.jump.medias.length > 0 ? swipeableviews : noMediaContent}
      {props.jump.medias.length > 1 ? (
        <MobileStepper
          steps={maxSteps}
          style={{ marginTop: 0, padding: 0 }}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
            </Button>
          }
        />
      ) : null}

      <CardContent style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Typography
            gutterBottom
            variant="h6"
            component="span"
            style={{ textAlign: "left", margin: "10px 0px 0px 0px" }}
          >
            {props.jump.name}
          </Typography>
          {getChipForDifficulty(props.jump.difficultyId)}
          <JumpTypeBox typeId={props.jump.jumpTypeId}/>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {dist ? (
            <Typography
              gutterBottom
              variant="caption"
              component="span"
              style={{ textAlign: "left" }}
            >
              {`${dist} miles away`}
            </Typography>
          ) : null}
        </div>

        <Typography
          style={{
            float: "left",
            textAlign: "left",
            margin: "10px 0px 10px 0px",
          }}
          variant="body2"
          color="text.secondary"
        >
          {props.jump.description}
        </Typography>
        <div style={{ textAlign: "left" }}></div>
      </CardContent>
      {user && user.id ? (
        <CardActions style={{ float: "right", height: "50px" }}>
          <UploadButton
            jump={props.jump}
            onSuccess={(r) => addedMedia(r)}
            onError={() => showError("Upload failed")}
            onFileChosen={(f) => setSelectedFile(f)}
          />
        </CardActions>
      ) : null}
    </Card>
  );

  return (
    <Modal
      open={props.open}
      onClose={() => props.onCancel()}
      disableAutoFocus
      style={{
        outline: 0,
        top: "20%",
      }}
    >
      {card}
    </Modal>
  );
}
