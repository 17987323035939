import { Button } from "@mui/material";
import { Circle, Square } from "@mui/icons-material";
import { ReactComponent as BlackDiamondLogo } from "../static/images/diamond.inline.svg";
import constants from "../static/constants.json";

import { ReactComponent as CliffLogo } from "../static/images/cliff0.svg";
import { ReactComponent as KnuckleLogo } from "../static/images/knuckle0.svg";
import { ReactComponent as BooterLogo } from "../static/images/booter0.svg";

export default function JumpTypeOption (props) {
    return (
      <Button
        variant="outlined"
        style={{
          backgroundColor: props.selected ? '#e5e9f2' : '#ffffff',
          borderWidth: props.selected ? '3px' : '1px',
          display: "flex",
          flexDirection: "column",
          width: "100px",
          height: "100px",

        }}
        onClick={() => props.onSelect()}
      >
        {getIconForJumpType(props.typeId)}
        <br />
        {props.text}
      </Button>
    );
  };
  
  const getIconForJumpType = (id) => {
    if (id == constants.jumpType.cliff) {
      return (
        <CliffLogo
          width={300}
          height={300}
          style={{
            height: "100px",
            width: "100px",
            color: "green",
          }}
        />
      );
    } else if (id == constants.jumpType.knuckle) {
      return (
        <KnuckleLogo
          style={{
            height: "80px",
            width: "80px",
            color: "blue",
          }}
        />
      );
    } else {
      return (
        <BooterLogo
          style={{
            height: "80px",
            width: "80px",
          }}
        />
      );
    }
  };