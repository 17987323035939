import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: null,
    name: '',
    firstName: '',
    lastName: '',
    photo: '',
    name: '',
    provider: '',
    location: null
    },
  reducers: {
    setUser: (state, action) => {
      state.provider = action.payload.provider;
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.photo = action.payload.photo;
      state.likes = action.payload.likes;
    },
    addLike: (state, action)  => {
      state.likes.push(action.payload);
    },
    setLocation: (state, action)  => {
      state.location = [action.payload.latitude, action.payload.longitude]
    },
    removeLike: (state, action)  => {
      state.likes = state.likes.filter(l => l.mediaId != action.payload.mediaId);
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUser, addLike, removeLike, setLocation } = userSlice.actions

export default userSlice.reducer