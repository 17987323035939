import React, { useEffect, useState } from "react";
import { Map, Marker, Overlay } from "pigeon-maps";
import { styled } from "@mui/material/styles";
import { stamenTerrain, maptiler } from "pigeon-maps/providers";
import useWindowDimensions from "../hooks/window";
import { useSelector, useDispatch } from "react-redux";
import { setUser, setLocation } from "../redux/userSlice";
import { ReactComponent as BooterLogo } from "../static/images/booter0.svg";
import {
  setCurrentJump,
  setFilteredJumps,
  setJumps,
  clearCurrentJump,
  sortJumpsByDistance,
} from "../redux/jumpsSlice";
import { setAreas, setCurrentArea } from "../redux/areasSlice";
import { showError, showSuccess, showWarning } from "../utils/notifier";
import { getColorForDifficulty } from "./DifficultyChips";
import FilterAndSort from "./FilterAndSort";
import {
  NearMe,
  Person,
  RadioButtonChecked,
  Add,
  Search,
  Close,
  Tune,
  List,
  AcUnit,
  WbSunny,
} from "@mui/icons-material";
import {
  Box,
  Menu,
  MenuItem,
  Switch,
  ClickAwayListener,
  Paper,
  IconButton,
  Tooltip,
  Fab,
  InputAdornment,
  TextField,
  Slide,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { UserAPI } from "../apis/userApi";
import { JumpApi } from "../apis/jumpApi";
import { AreaApi } from "../apis/areaApi";
import CreateJumpDialog from "./CreateJumpModal";
import JumpOverlay from "./JumpOverlay";
import UserMenu from "./UserMenu";
import JumpList from "./JumpList";
import JumpPin from "./JumpPin";
import { BlackDiamond, Knuckle, SkiResort, Mountain } from "./icons";

const maptilerProvider = maptiler(
  process.env.REACT_APP_MAPTILER_API_KEY,
  "winter"
);

const maptilerProviderSummer = maptiler(
  process.env.REACT_APP_MAPTILER_API_KEY,
  "outdoor"
);

export function MyMap() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const currentArea = useSelector((state) => state.areas.current);
  const jumps = useSelector((state) => state.jumps.list);
  const areas = useSelector((state) => state.areas);
  const [season, setSeason] = useState(false); // true for summer
  const filteredJumps = useSelector((state) => state.jumps.filteredList);
  const jump = useSelector((state) => state.jumps.current);
  const [center, setCenter] = useState([40.633094, -111.515027]); // default somewhere near PC
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [positionHover, setPositionHover] = useState(false);
  const [showListView, setShowListView] = useState(false);
  const [createJumpHover, setCreateJumpHover] = useState(false);
  const [createJumpOpen, setCreateJumpOpen] = useState(false);
  const [focusedJump, setFocusedJump] = useState(null);
  const [query, setQuery] = useState("");
  const theme = useTheme();
  const [zoom, setZoom] = useState(11);
  const [geoActive, setGeoActive] = useState(false);
  const [myLocation, setMyLocation] = useState(null);
  const [centerMe, setCenterMe] = useState(false);
  const { height, width } = useWindowDimensions();

  const listSettings = useSelector((state) => state.jumps.listSettings);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [dimensions, setDimensions] = useState([100, 100]);
  const geoSuccess = (position) => {
    setGeoActive(true);
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    dispatch(setLocation({ latitude, longitude }));
    dispatch(sortJumpsByDistance({ latitude, longitude }));
    setMyLocation([latitude, longitude]);
  };

  const geoError = () => {
    showError("Unable to retrieve location.  Some features are disabled.");
  };

  useEffect(() => {
    setDimensions([width, height]);
  }, [width]);

  // center map on user's location when indicated
  // effect fires when location access granted, then my location is pressed
  useEffect(() => {
    if (centerMe) {
      // when button is pressed
      setCenter(myLocation);
      setCenterMe(false);
    }
  }, [myLocation]);

  useEffect(() => {
    if (jumps) {
      const result = jumps.filter((j) => {
        return j.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
      });
      dispatch(setFilteredJumps(result));
    }
  }, [query]);

  useEffect(() => {
    if (filteredJumps.length == 1) {
      setCenter([filteredJumps[0].latitude, filteredJumps[0].longitude]);
    }
  }, [filteredJumps]);

  // jump to selected area's center (TODO - re-enable)
  useEffect(() => {
    if (currentArea.latitude && currentArea.longitude) {
      const loc = [currentArea.latitude, currentArea.longitude];
      setCenter(loc);
      console.log("we should jump to a new area", currentArea);
    }
  }, [currentArea]);

  useEffect(() => {
    if (jump) {
      setFocusedJump(jump);
    }
  }, [jump]);

  const getJumps = async () => {
    try {
      const r = await JumpApi.list();

      dispatch(setJumps(r));
      dispatch(setFilteredJumps(r));
    } catch (error) {
      showError("Failed to get jumps");
      console.log("error fetching jumps: ", error);
    }
    // console.log("user is ", user);
  };

  const getAreas = async () => {
    try {
      const r = await AreaApi.list();
      dispatch(setAreas(r));
    } catch (error) {
      showError("Failed to get areas");
      console.log("error fetching areas: ", error);
    }
  };

  const getPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
    } else {
      alert("Geolocation not supported");
    }
  };

  const getLoggedInUser = async () => {
    try {
      const u = await UserAPI.get();
      if (u.name) {
        showSuccess(`Signed in as ${u.name}`);
      } else {
        showWarning("Not signed in");
      }

      dispatch(setUser(u));
    } catch (error) {
      dispatch(setUser({}));
      localStorage.removeItem("token");
      localStorage.removeItem("token_type");
      showWarning("Not signed in");
    }
  };

  useEffect(() => {
    // get user's location if allowed
    getPosition();
    getJumps();
    getAreas();
    getLoggedInUser();
  }, []);

  const positionButtonStyle = {
    color: "white",
    borderRadius: "4px",
    backgroundColor: positionHover
      ? theme.palette.primary.dark
      : theme.palette.primary.main,
    width: "56px",
    height: "56px",
  };

  const myPositionButton = <div></div>;

  // const createJumpButtonStyle = {
  //   borderRadius: "4px",
  //   color: "white",
  //   width: "56px",
  //   height: "56px",
  //   backgroundColor: createJumpHover
  //     ? theme.palette.primary.dark
  //     : theme.palette.primary.main,
  // };

  // const createJumpButton = (
  //   <IconButton
  //     color="primary"
  //     variant="contained"
  //     style={createJumpButtonStyle}
  //     onMouseEnter={() => setCreateJumpHover(true)}
  //     onMouseLeave={() => setCreateJumpHover(false)}
  //     onClick={() => {
  //       setCreateJumpOpen(true);
  //     }}
  //   >
  //     <Add />
  //   </IconButton>
  // );

  const myLocationOverlay = (
    <Overlay anchor={myLocation} offset={[0, 0]}>
      <RadioButtonChecked color="primary" />
    </Overlay>
  );

  const menu = (
    <UserMenu
      onLoginSuccess={() => getLoggedInUser()}
      isOpen={isMenuOpen}
      onCancel={() => setIsMenuOpen(false)}
    />
  );

  const handleClose = (a) => {
    dispatch(setCurrentArea(a));
    setAnchorEl(null);
  };

  const areasMenu = (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={() => {
        setAnchorEl(null);
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {areas.list.map((a) => (
        <MenuItem onClick={() => handleClose(a)}>{a.name}</MenuItem>
      ))}
    </Menu>
  );

  const moveToJumpWithId = (id) => {
    const j = jumps.find((j) => j.id === id);
    setCenter([j.latitude, j.longitude]);
    dispatch(setCurrentJump(j));
  };

  const jumpPins = filteredJumps
    ? filteredJumps.map((j) => {
        return (
          <Overlay
            key={j.id}
            anchor={[j.latitude, j.longitude]}
            offset={[0, 0]}
            style={{ background: "none" }}
          >
            <JumpPin
              jump={j}
              onSelect={() => {
                setCenter([j.latitude, j.longitude]);
                dispatch(setCurrentJump(j));
              }}
            />
          </Overlay>
        );
      })
    : null;

  const closedJumpOverlay = () => {
    dispatch(clearCurrentJump());
    setFocusedJump(null);
  };

  const focusedJumpOverlay =
    focusedJump && focusedJump.id ? (
      <JumpOverlay
        user={user}
        open={!!focusedJump}
        jump={focusedJump}
        onCancel={() => closedJumpOverlay()}
      />
    ) : null;

  const createJumpDialog = (
    <CreateJumpDialog
      open={createJumpOpen}
      onClose={() => setCreateJumpOpen(false)}
    />
  );

  const jumpList = (
    <JumpList
      open={showListView}
      onClose={() => setShowListView(false)}
      onFocusJump={(id) => moveToJumpWithId(id)}
    />
  );


  const bottomBar = (
    <Box
      style={{
        position: "absolute",
        bottom: "0",
        width: "100%",
        height: "6rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <Fab
        color="primary"
        onClick={(e) => setAnchorEl(e.target)}
        aria-label="add"
        style={{}}
      >
        <Mountain color="white" style={{ height: 20, width: 20 }} />
      </Fab>
      <Fab
        color="primary"
        onClick={(e) => {
          setSeason(!season);
        }}
        aria-label="add"
        style={{}}
      >
        {season ? <WbSunny /> : <AcUnit />}
      </Fab>
      <Fab
        color="primary"
        onClick={() => setCreateJumpOpen(true)}
        aria-label="add"
      >
        <Add />
      </Fab>
      <Fab
        color="primary"
        onClick={() => {
          setCenterMe(true);
          getPosition();
        }}
        aria-label="add"
      >
        <NearMe />
      </Fab>
      <Fab
        color="primary"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        aria-label="add"
      >
        <Person />
      </Fab>
    </Box>
  );

  const myLocationButton = (
    <Fab
      color="primary"
      onClick={() => viewMyLocationPressed()}
      aria-label="add"
      style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
    >
      <NearMe />
    </Fab>
  );

  const jumpListContainer = (
    <ClickAwayListener
      style={{ border: "1px solid", background: "red" }}
      onClickAway={() => {
        console.log("clicked away");
        //setOpen(false);
        // props.onClose();
      }}
    >
      <Slide in={showListView} direction="up">
        <Paper
          sx={{
            position: "absolute",
            overflow: "scroll",
            textAlign: "center",
            marginLeft: "2rem",
            marginRight: "2rem",
            width: "calc(100% - 4rem)",
            zIndex: 10000,
            top: "5rem",
            height: "100%",
          }}
        >
          {jumpList}
        </Paper>
      </Slide>
    </ClickAwayListener>
  );

  const header2 = (
    <Box
      style={{
        width: "100%",

        height: "5rem",
        position: "absolute",
        alignItems: "center",
        top: "0rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        margin: "auto",
      }}
    >
      <TextField
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Find jumps"
        style={{ width: "70%", margin: "10px 0px 10px 0px", padding: "10px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Clear input">
                <IconButton
                  aria-label="clearInput"
                  onClick={() => {
                    setQuery("");
                    dispatch(setFilteredJumps(jumps));
                  }}
                >
                  <Close />
                </IconButton>
              </Tooltip>
              <Tooltip title="Show list">
                <IconButton
                  aria-label="open-filter-options"
                  onClick={() => setShowListView(!showListView)}
                >
                  <List />
                </IconButton>
              </Tooltip>

              <Tooltip title="Filter and sort">
                <IconButton
                  aria-label="open-filter-options"
                  onClick={() => setOptionsOpen(true)}
                >
                  <Tune />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
          style: {
            paddingLeft: "1rem",
            paddingRight: "1rem",
            borderRadius: "5rem",
            backgroundColor: "white",
          },
        }}
      ></TextField>
    </Box>
  );

  const viewMyLocationPressed = () => {
    setCenterMe(true);
    getPosition();
  };

  const applyFilters = () => {
    if (jumps) {
      let result = jumps
        .filter((j) => {
          // apply query if any
          if (query.length > 0) {
            return j.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
          } else return true;
        })
        .filter((j) => {
          // apply difficulty filter
          return listSettings.difficulties.indexOf(j.difficultyId) > -1;
        });
      setFilteredJumps(result);
    }
  };

  return (
    <div onClick={() => {}}>
      <Map
        height={dimensions[1]}
        width={dimensions[0]}
        center={center}
        provider={season ? maptilerProviderSummer : maptilerProvider}
        defaultCenter={center}
        defaultZoom={14}
        onBoundsChanged={({ center, zoom }) => {
          setCenter(center);
          setZoom(zoom);
        }}
        onClick={() => {
          console.log("map box clicked");
          setShowListView(false);
        }}
      >
        {jumpPins}
        {myLocation ? myLocationOverlay : null}
      </Map>

      {menu}
      {areasMenu}
      {createJumpDialog}

      {/* ui overlays */}
      {jumpListContainer}
      {/* {myLocationButton}
      {areasMenuButton} */}
      {bottomBar}
      {/* {seasonToggle} */}
      {/* {testButton}
      {testArea} */}
      {header2}
      {focusedJumpOverlay}
      <FilterAndSort
        open={optionsOpen}
        onClose={() => {
          applyFilters();
          setOptionsOpen(false);
        }}
      />

      {/* ui buttons */}
      {/* {newJump} */}
      {/* {userMenuButton} */}
    </div>
  );
}
