import { Chip, Button } from "@mui/material";
import { Circle, Square } from "@mui/icons-material";
import { ReactComponent as BlackDiamondLogo } from "../static/images/black_diamond.svg";
import constants from "../static/constants.json";

const EasyChip = () => {
  return (
    <Chip
      style={{ borderWidth: "2px", fontWeight: 900 }}
      icon={<Circle fontSize="small" />}
      label="Easy"
      color="success"
      variant="outlined"
    />
  );
};

const MediumChip = () => {
  return (
    <Chip
      style={{ borderWidth: "2px", fontWeight: 900 }}
      icon={<Square fontSize="small" />}
      label="Intermediate"
      color="primary"
      variant="outlined"
    />
  );
};

const HardChip = () => {
  return (
    <Chip
      avatar={
        <BlackDiamondLogo
          style={{
            height: "15px",
            width: "15px",
          }}
        />
      }
      label="Expert"
      style={{

        borderWidth: "2px",
        borderColor: "black",
        fontWeight: 900,
      }}
      variant="outlined"
    />
  );
};

const getChipForDifficulty = (diff) => {
  if (diff == constants.difficulty.easy) {
    return EasyChip();
  } else if (diff == constants.difficulty.medium) {
    return MediumChip();
  } else {
    return HardChip();
  }
};

const getColorForDifficulty = (diff) => {
  if (diff == constants.difficulty.easy) {
    return "green";
  } else if (diff == constants.difficulty.medium) {
    return "#1876d1";
  } else {
    return "black";
  }
};

export {
  EasyChip,
  MediumChip,
  HardChip,
  getChipForDifficulty,
  getColorForDifficulty,
};
