import * as React from "react";
import { useEffect, useState } from "react";
import constants from "../static/constants.json";
import { IconButton, Tooltip, Box } from "@mui/material";
import { Flag } from "@mui/icons-material";
import { Upload } from "@mui/icons-material";
import { JumpApi } from "../apis/jumpApi";
import { getColorForDifficulty } from "./DifficultyChips";

import { Cliff, Knuckle, Booter } from "./icons";

const positionXForJumpType = (typeId) => {
  switch (typeId) {
    case constants.jumpType.knuckle:
      return 14;
    case constants.jumpType.cliff:
      return 15;

    default:
      return -2;
  }
};

const positionYForJumpType = (typeId) => {
  switch (typeId) {
    case constants.jumpType.knuckle:
      return 0;
    case constants.jumpType.cliff:
      return -5;

    default:
      return -9;
  }
};

export default function JumpPin(props) {
  const [hovered, setHovered] = useState(false);

  const defaultLogoStyle = {
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    if (props.jump.jumpTypeId == constants.jumpType.booter) {
      console.log("booter, ", props.jump.name);
      setCurrLogo(booterLogo);
    } else if (props.jump.jumpTypeId == constants.jumpType.cliff) {
      setCurrLogo(cliffLogo);
    } else {
      console.log("knuck", props.jump.name);
      setCurrLogo(knuckleLogo);
    }
  }, [props.jump.typeId]);

  const cliffLogo = <Cliff style={defaultLogoStyle} />;
  const knuckleLogo = <Knuckle style={defaultLogoStyle} />;
  const booterLogo = <Booter style={defaultLogoStyle} />;

  const [currLogo, setCurrLogo] = useState(booterLogo);

  const item = (
    <Box
      onMouseEnter={() => {
        setHovered(true);
      }}
      onClick={() => {
        props.onSelect();
      }}
      onMouseLeave={() => setHovered(false)}
      style={{
        background: "none",
        width: hovered ? "50px" : "40px",
        height: hovered ? "50px" : "40px",
      }}
    >
      {currLogo}
      <Flag
        color={getColorForDifficulty(props.jump.difficultyId)}
        style={{
          top: `${positionYForJumpType(props.jump.jumpTypeId)}px`,
          left: `${positionXForJumpType(props.jump.jumpTypeId)}px`,
          position: "absolute",
          color: getColorForDifficulty(props.jump.difficultyId),
          height: hovered ? "25px" : "20px",
          width: hovered ? "25px" : "20px",
        }}
      />
    </Box>
  );

  // const oldItem = (
  //   <Box
  //             style={{ background: "none", width: "%100", height: "%100" }}
  //             onMouseEnter={() => {
  //               if (hoveredJump != j.id) setHoveredJump(j.id);
  //             }}
  //             onMouseLeave={() => setHoveredJump(null)}
  //           >
  //             <BooterLogo
  //               style={{
  //                 height: hoveredJump == j.id ? "50px" : "40px",
  //                 width: hoveredJump == j.id ? "50px" : "40px",
  //               }}
  //               onClick={() => {
  //                 setCenter([j.latitude, j.longitude]);
  //                 dispatch(setCurrentJump(j));
  //               }}
  //             />
  //             <Flag
  //               color={getColorForDifficulty(j.difficultyId)}
  //               style={{
  //                 top: "-5px",
  //                 left: "-2px",
  //                 position: "absolute",
  //                 color: getColorForDifficulty(j.difficultyId),
  //                 height: hoveredJump == j.id ? "25px" : "20px",
  //                 width: hoveredJump == j.id ? "25px" : "20px",
  //               }}
  //             />
  //           </Box>
  // );

  return item;
}
