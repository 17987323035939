import * as React from "react";
import { useRef, useState, useEffect } from "react";
import { ListItem, Typography, Tooltip } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import constants from "../static/constants.json";
import { ListItemContent } from "@mui/joy";
import ReactPlayer from "react-player";
import { calculateDistance } from "../utils/geography";

import { CardMedia, Box, Divider, IconButton } from "@mui/material";
import { getChipForDifficulty } from "./DifficultyChips";
import { Favorite, Map } from "@mui/icons-material";
import JumpTypeBox from "./JumpTypeBox";
export default function JumpListItem(props) {
  const [isVisible, setVisible] = useState(false);
  const user = useSelector((state) => state.user);
  const playerRef = useRef();
const mediaBasePath = `https://storage.googleapis.com/${process.env.REACT_APP_BUCKET_NAME}`;
  //swipeview
  const [activeStep, setActiveStep] = React.useState(0);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    if (domRef.current) {
      observer.observe(domRef.current);
    }

    return () => {
      if (domRef.current) observer.unobserve(domRef.current);
      else return null;
    }; // clean up
  }, []);

  // leave me here for debugging visibility
  // useEffect(() => {
  //   console.log(props.jump.name, "is visible?" , isVisible);
  // }, [isVisible]);

  const swipeView = (
    <SwipeableViews
      index={activeStep}
      onChangeIndex={handleStepChange}
      enableMouseEvents
      style={{ background: "8px solid blue" }}
    >
      {props.jump.medias.map((media, index) => {
        const userLikedThisMedia =
          user && user.id
            ? user.likes.find((l) => l.mediaId == media.id)
            : false;
        const inner =
          media.mediaTypeId === constants.mediaType.image ? (
            <Box
              component="img"
              sx={{ height: "40vh", width: "100%", borderRadius: "10px" }}
              src={`${mediaBasePath}/${
                isVisible ? media.key : "placeholder.png"
              }`}
            />
          ) : (
            <ReactPlayer
              style={{
                background: "#000000",

                borderRadius: "10px",
              }}
              width={"100%"}
              ref={playerRef}
              loop
              playing={isVisible && activeStep == index}
              url={`${mediaBasePath}/${
                isVisible ? media.key : "placeholder.png"
              }`}
            />
          );
        return (
          <Box key={`media_${index}`} style={{ margin: "10px" }}>
            {inner}
          </Box>
        );
      })}
    </SwipeableViews>
  );

  const header = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0px 10px 0px 10px",
      }}
      ref={domRef}
    >
      <Typography variant="h5">{props.jump.name}</Typography>
      {getChipForDifficulty(props.jump.difficultyId)}
    </div>
  );

  // maybe use MyLocation icon? Room icon? TravelExplore?
  const viewOnMapButton = (
    <Tooltip title="View on map">
      <IconButton onClick={() => props.viewOnMap(props.jump.id)}>
        {" "}
        <Map />{" "}
      </IconButton>
    </Tooltip>
  );

  const subheader1 =
    props.jump.dist != null ? (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          margin: "0px 10px 0px 10px ",
        }}
      ></div>
    ) : null;

  const subheader2 =
    props.jump.dist != null ? (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0px 10px 0px 10px ",

          alignItems: 'center'
        }}
      >
        <Typography variant="caption">
          {" "}
          {props.jump.dist != null ? `${props.jump.dist} miles away` : ""}
        </Typography>
        <JumpTypeBox typeId={props.jump.jumpTypeId}/>
      </div>
    ) : null;

  const actionPanel = (
    <div
      style={{
        display: "flex",

        flexDirection: "row-reverse",

        justifyContent: "space-between",
        alignItems: "center",
        background: "orange",
        border: "1px solid red",
        margin: "0px 10px 0px 10px",
      }}
    >
      <IconButton style={{ float: "left" }} aria-label="like">
        <Favorite />
      </IconButton>
    </div>
  );

  const body = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography style={{ margin: "10px" }}>
        {props.jump.description}
      </Typography>
    </div>
  );

  return (
    <ListItem style={{ marginBottom: "2rem", maxWidth: "600px" }}>
      <ListItemContent>
        {header}
        {subheader1}
        {subheader2}
        {swipeView}
      </ListItemContent>
      <Divider />
    </ListItem>
  );
}
