import haversine from "haversine-distance";

// get distance in miles
const calculateDistance = (lat1, long1, lat2, long2) => {
  var point1 = { lat: lat1, lng: long1 };
  var point2 = { lat: lat2, lng: long2 };

  var haversine_m = haversine(point1, point2); //Results in meters (default)
  var haversine_km = haversine_m / 1000; //Results in kilometers
  let haversine_miles = haversine_km * 0.6214;
  
  return Math.round(haversine_miles * 100) / 100;
};

export { calculateDistance };
