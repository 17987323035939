import * as React from "react";
import { useState, useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";

import MenuItem from "@mui/material/MenuItem";
import Landscape from "@mui/icons-material/Landscape";
import { useTheme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";

import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Drawer,
  Paper,
  List,
  Box,
  Tooltip,
  ListItem,
  Divider,
  Button,
  ListSubheader,
  InputAdornment,
  TextField,
  ClickAwayListener,
  Slide,
} from "@mui/material";
import JumpListItem from "./JumpListItem";
import { Close, Map, Search, Tune } from "@mui/icons-material";
const ITEM_HEIGHT = 48;
import FilterAndSort from "./FilterAndSort";

const classes = {
  drawerPaper: {
    height: "100%",
    backgroundColor: "#ff0000",
  },
};

export default function JumpList(props) {
  const dispatch = useDispatch();
  const playerRef = useRef();
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(props.open);
  const [menuHover, setMenuHover] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const jumps = useSelector((state) => state.jumps.list);
  const listSettings = useSelector((state) => state.jumps.listSettings);
  const [filteredJumps, setFilteredJumps] = useState(jumps);

  useEffect(() => {
    console.log("open changed ? ", props.open);
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    setFilteredJumps(jumps);
  }, [jumps]);

  useEffect(() => {
    if (jumps) {
      const result = jumps.filter((j) => {
        return j.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
      });

      setFilteredJumps(result);
    }
  }, [query]);

  const applyFilters = () => {
    if (jumps) {
      let result = jumps
        .filter((j) => {
          // apply query if any
          if (query.length > 0) {
            return j.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
          } else return true;
        })
        .filter((j) => {
          // apply difficulty filter
          return listSettings.difficulties.indexOf(j.difficultyId) > -1;
        });
      setFilteredJumps(result);
    }
  };

  const jumpsContent =
    filteredJumps && filteredJumps.length > 0 ? (
      filteredJumps.map((j, i) => (
        <JumpListItem
          jump={j}
          viewOnMap={(id) => {
            props.onClose();
            props.onFocusJump(id);
          }}
        />
      ))
    ) : (
      <Typography
        variant="h5"
        style={{
          margin: "auto",
          textAlign: "center",
          height: "400px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        No Results
      </Typography>
    );

  return (
    <Box
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
        width: "100%",
      }}
    >
      <List
        style={{
          marginTop: "1rem",
          overflow: "scroll",
          paddingTop: "0px",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {jumpsContent}
      </List>
    </Box>
  );
}
