import * as React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Slide,
  TextField,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import {
  NearMe,
  NearMeDisabled,
  AddLocation,
  MyLocation,
} from "@mui/icons-material";
import constants from "../static/constants.json";
import JumpTypeOption from "./JumpTypeOption";
import DifficultyOption from "./DifficultyOption";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

import { Map, Overlay } from "pigeon-maps";
import { useSelector } from "react-redux";
import { JumpApi } from "../apis/jumpApi";
import { showSuccess } from "../utils/notifier";
import { useDispatch } from "react-redux";
import { setLocation } from "../redux/userSlice";
import { sortJumpsByDistance } from "../redux/jumpsSlice";
export default function CreateJumpDialog(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [difficulty, setDifficulty] = useState(null);
  const [type, setType] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [center, setCenter] = useState([0, 0]);
  const [geoActive, setGeoActive] = useState(false);
  const [centerMe, setCenterMe] = useState(false);
  const [myLocation, setMyLocation] = useState(null);
  const handleClickOpen = () => {
    //setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    setDifficulty(null);
    setType(null);
    setName(null);
    setDescription(null);
    props.onClose();
  };

  useEffect(() => {
    if (centerMe) {
      // when button is pressed
      setCenter(myLocation);
      setCenterMe(false);
    }
  }, [myLocation]);

  const handleSubmit = async () => {
    // check for validation errors, display them,
    // if all good proceed
    const body = {
      name,
      description,
      difficultyId: difficulty,
      jumpTypeId: type,
      latitude: center[0],
      longitude: center[1],
    };
    try {
      let j = await JumpApi.create(body);

      console.log("created j!");

      showSuccess(`Created ${name}`);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (user.location && user.location.length > -1) {
      setCenter(user.location);
    }
  }, [user.location]);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
    } else {
      alert("Geolocation not supported");
    }
  };
  useEffect(() => {
    if (centerMe) {
      // when button is pressed
      setCenter(myLocation);
      setCenterMe(false);
    }
  }, [myLocation]);
  const headerStyle = { border: "none", width: "120px" };

  const sectionContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };
  const optionsStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: "10px",
    alignItems: "center",
    // border: '1px solid red'
  };

  const gridItemStyleType = {
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
  };

  const locationOverlay = (
    <Overlay anchor={center} offset={[0, 0]}>
      <AddLocation color="primary" />
    </Overlay>
  );

  const geoSuccess = (position) => {
    setGeoActive(true);
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    dispatch(setLocation({ latitude, longitude }));
    dispatch(sortJumpsByDistance({ latitude, longitude }));
    setMyLocation([latitude, longitude]);
  };

  const geoError = () => {
    showError("Unable to retrieve location.  Some features are disabled.");
  };

  const mainGrid = (
    <Grid
      container
      style={{
        display: "flex",
        border: "none",
        padding: "10px",
        alignItems: "center",
      }}
    >
      <Grid
        item
        xs={12}
        container
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginBottom: '1rem'
        }}
      >
        <Typography variant="h6" style={{}}>
          Create a New Jump
        </Typography>
      </Grid>
      <Grid item xs={3} style={{padding: '1rem'}}>
        <Typography variant="h8" style={ {}}>
          Name
        </Typography>
      </Grid>
      <Grid item xs={9} style={{padding: '1rem'}}>
        <TextField
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h8" style={{ padding: '1rem' }}>
          Description
        </Typography>
      </Grid>
      <Grid item xs={9} style={{padding: '1rem'}}>
        <TextField
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ width: "100%" }}
        />
      </Grid>
      
      <Grid item xs={3} style={{}}>
        <Typography variant="h8" style={{padding: '1rem'}}>
          Difficulty
        </Typography>
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DifficultyOption
          selected={difficulty == constants.difficulty.easy}
          text="Easy"
          difficulty={constants.difficulty.easy}
          onSelect={() => setDifficulty(constants.difficulty.easy)}
        />
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DifficultyOption
          selected={difficulty == constants.difficulty.medium}
          text="Medium"
          difficulty={constants.difficulty.medium}
          onSelect={() => setDifficulty(constants.difficulty.medium)}
        />
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DifficultyOption
          selected={difficulty == constants.difficulty.hard}
          text="Hard"
          difficulty={constants.difficulty.hard}
          onSelect={() => setDifficulty(constants.difficulty.hard)}
        />
      </Grid>
      <Grid item xs={3} style={{ padding: '1rem' }}>
        <Typography variant="h8" style={headerStyle}>
          Type
        </Typography>
      </Grid>
      <Grid item xs={3} style={gridItemStyleType}>
        <JumpTypeOption
          selected={type == constants.jumpType.cliff}
          text="Cliff"
          typeId={constants.jumpType.cliff}
          onSelect={() => setType(constants.jumpType.cliff)}
        />
      </Grid>
      <Grid item xs={3} style={gridItemStyleType}>
        <JumpTypeOption
          selected={type == constants.jumpType.knuckle}
          text="Knuckle"
          typeId={constants.jumpType.knuckle}
          onSelect={() => setType(constants.jumpType.knuckle)}
        />
      </Grid>
      <Grid item xs={3} style={gridItemStyleType}>
        <JumpTypeOption
          selected={type == constants.jumpType.booter}
          text="Booter"
          typeId={constants.jumpType.booter}
          onSelect={() => setType(constants.jumpType.booter)}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h8" style={{padding: '1rem'}}>
          Location
        </Typography>
      </Grid>
      
      <Grid
        item
        xs={9}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
        }}
      >
        <Box style={{ width: "360px", height: "360px", marginTop: "10px" }}>
          <Map
            height={360}
            width={360}
            center={center}
            onBoundsChanged={(result) => {
              setCenter(result.center);
              console.log(result.center);
            }}
            style={{ display: "flex" }}
            defaultCenter={center}
            defaultZoom={16}
          >
            <div style={{ position: "absolute", left: "150px", top: "150px" }}>
              <MyLocation />
            </div>

            <div style={{ position: "absolute", left: "10px", top: "10px" }}>
              <IconButton
                onClick={() => {
                  getCurrentLocation();
                }}
              >
                <NearMe />
              </IconButton>
            </div>

            <div style={{ position: "absolute", right: "3px", bottom: "3px" }}>
              <Typography variant="caption">{center[0]}</Typography>
              <br />
              <Typography variant="caption">{center[1]}</Typography>
            </div>
            {/* {center ? locationOverlay : null} */}
          </Map>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          style={{
            width: "120px",
            height: "120px",
            margin: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        ></Box>
      </Grid>
    </Grid>
  );

  const nameSection = (
    <Box style={sectionContainerStyle}>
      <Box style={optionsStyle}>
        <Typography variant="h6" style={headerStyle}>
          Name
        </Typography>
      </Box>
    </Box>
  );

  const descriptionSection = (
    <Box style={sectionContainerStyle}>
      <Box style={optionsStyle}>
        <Typography variant="h6" style={headerStyle}>
          Description
        </Typography>
        <TextField
          variant="outlined"
          value={description}
          rows={2}
          multiline
          onChange={(e) => setDescription(e.target.value)}
          style={{ width: "400px", marginLeft: "10px" }}
        />
      </Box>
    </Box>
  );

  const difficultySection = (
    <Box style={sectionContainerStyle}>
      <Box style={optionsStyle}>
        <Typography variant="h6" style={headerStyle}>
          Difficulty
        </Typography>
      </Box>
    </Box>
  );

  const typeSection = (
    <Box style={sectionContainerStyle}>
      <Box style={optionsStyle}>
        <Typography variant="h6" style={headerStyle}>
          Type
        </Typography>
      </Box>
    </Box>
  );

  const locationSection = (
    <Box style={sectionContainerStyle}>
      <Box style={optionsStyle}>
        <Typography variant="h6" style={headerStyle}>
          Location
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth={"sm"}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent style={{ border: "none" }}>
        <Box style={{ border: "none" }}>{mainGrid}</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Create</Button>
      </DialogActions>
    </Dialog>
  );
}
