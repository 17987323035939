import { Button, Box, Typography } from "@mui/material";
import { Circle, Square } from "@mui/icons-material";
import { ReactComponent as BlackDiamondLogo } from "../static/images/diamond.inline.svg";
import constants from "../static/constants.json";

import { ReactComponent as CliffLogo } from "../static/images/cliff0.svg";
import { ReactComponent as KnuckleLogo } from "../static/images/knuckle0.svg";
import { ReactComponent as BooterLogo } from "../static/images/booter0.svg";

export default function JumpTypeBox (props) {
    return (
        <Box style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            margin: "0px 10px 0px 10px ",
            
          }}>
            {getIconForJumpType(props.typeId)}
            <Typography
              variant="overline"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {getTextForJumpType(props.typeId)}
            </Typography>
          </Box>
    );
  };

  const getTextForJumpType = (id) => {
    if (id == constants.jumpType.cliff) {
        return "Drop"
      } else if (id == constants.jumpType.knuckle) {
        return "Roller"
      } else {
        return "Jump"
      }
  }
  
  const getIconForJumpType = (id) => {
    if (id == constants.jumpType.cliff) {
      return (
        <CliffLogo
          style={{
            height: "15px",
            width: "15px",
          }}
        />
      );
    } else if (id == constants.jumpType.knuckle) {
      return (
        <KnuckleLogo
          style={{
            height: "15px",
            width: "15px",
          }}
        />
      );
    } else {
      return (
        <BooterLogo
          style={{
            height: "15px",
            width: "15px",
          }}
        />
      );
    }
  };