import * as React from "react";
import {
  Drawer,
  Box,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  Checkbox,
  ListSubheader,
  Radio,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { Close } from "@mui/icons-material";
import { EasyChip, HardChip, MediumChip } from "./DifficultyChips";
import {
  addDifficultyFilter,
  removeDifficultyFilter,
  resetDefaults,
} from "../redux/jumpsSlice";
import constants from '../static/constants.json';
import { useSelector, useDispatch } from "react-redux";
export default function FilterAndSort(props) {
  const listSettings = useSelector((state) => state.jumps.listSettings);
  const dispatch = useDispatch();

  const resetFilterAndSortDefaults = () => {
    dispatch(resetDefaults({}));
  };

  const setDifficultyFilter = (difficultyId, value) => {
    if(value) {
        dispatch(addDifficultyFilter({difficultyId}))
    } else {
        dispatch(removeDifficultyFilter({difficultyId}))
    }
  }

  return (
    <Drawer
      open={props.open}
      anchor="bottom"
      onClose={() => props.onClose()}
      PaperProps={{
        sx: {
          overflow: "hidden",
          width: {
            margin: "auto",
            xs: 300,
            sm: 500,
          },
          height: "80%",
        },
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ margin: "10px" }} variant="h5">
          Filters
        </Typography>
        <IconButton aria-label="closeOptions" onClick={() => props.onClose()}>
          <Close />
        </IconButton>

      </Box>
      <List>
        <ListSubheader>Difficulty</ListSubheader>
        <ListItem>
          {" "}
          <Checkbox checked={listSettings.difficulties.indexOf(constants.difficulty.easy) > -1} value={constants.difficulty.easy} onClick={(e) => setDifficultyFilter(e.target.value,e.target.checked)}/>
          <EasyChip />
        </ListItem>
        <ListItem>
          {" "}
          <Checkbox checked={listSettings.difficulties.indexOf(constants.difficulty.medium) > -1} value={constants.difficulty.medium} onClick={(e) => setDifficultyFilter(e.target.value,e.target.checked)}/>
          <MediumChip />
        </ListItem>
        <ListItem>
          {" "}
          <Checkbox checked={listSettings.difficulties.indexOf(constants.difficulty.hard) > -1} value={constants.difficulty.hard} onClick={(e) => setDifficultyFilter(e.target.value,e.target.checked)}/>
          <HardChip />
        </ListItem>
        {/* 
        enable me when other things to sort by
        <ListSubheader>Sort By</ListSubheader>
        <ListItem>
          {" "}
          <Radio disabled defaultChecked /> Nearest
        </ListItem> */}
      </List>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button onClick={() => resetFilterAndSortDefaults()}>
            Reset to Default
        </Button>
        

      </Box>
    </Drawer>
  );
}
